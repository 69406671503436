<template>
    <div class="shortlist-main-container">
        <main-header></main-header>
        <div class="vld-parent">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="fullPage">
            </loading>
        </div>
        <breadcrumbs></breadcrumbs>

        <div class="wrapper wrapper--full wrapper--grey">
            <div class="wrapper">

                <div class="shortlist">
                    <div class="shortlist__heading">
                        <h1>Shortlisted Vehicles</h1>
                        <small v-if="this.$store.state.total > 0 && this.likes.length > 0 && this.ready === true">You have {{ this.$store.state.total }} Cars Shortlisted</small>
                        <div class="shortlist__none" v-if="(this.likes.length == 0 || this.$store.state.total == 0) && this.ready == true">You have not shortlisted any vehicles</div>
                    </div>
                    <car-listings modifier="carlistings--shortlist"></car-listings>
                </div>

            </div>
        </div>
        <main-footer></main-footer>
    </div>
</template>

<script>
    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'
    import CarListings from '@/components/partials/CarListings'
    import {mapMutations, mapState, mapActions} from "vuex";
    import Loading from 'vue-loading-overlay';
    import Breadcrumbs from '@/components/partials/Breadcrumbs';

    export default {
        name: "Shortlist",
        components: {
            MainHeader,
            MainFooter,
            CarListings,
            Loading,
            Breadcrumbs
        },

        data: function () {
            return {
                fullPage: true,
                url: null,
                likes: [],
                ready: false,
                title: "Your Shortlist | Terrific.ie",
                description: "Your shortlisted cars can be viewed here.",
                keywords: "Cars for Sale, Used Cars, Cars, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters, Land Rover",
                robots: "all"
            }
        },
        metaInfo() {
            return {
                title: this.title,
            };
        },
        mounted(){
            this.setIsLoading(true);
            this.setCurrentPageName('shortlist');
            this.loadLikes();
            this.getShortlist();
        },
        serverPrefetch () {
            this.$ssrContext.title = this.title;
            this.$ssrContext.description = this.description;
            this.$ssrContext.keywords = this.keywords;
            this.$ssrContext.robots = this.robots;
        },
        computed:{
            ...mapState([
                'resultsLoaded',
                'searchResults',
                'urlParams',
                'isLoading'
            ])
        },
        methods:{
            ...mapMutations([
                'setCurrentFilter',
                'setSearchOrderBy',
                'setRequestedPage',
                'setSearchFilters',
                'setJustLoaded',
                'setUrlParams',
                'setSearchResults',
                'setBackendPagination',
                'setCurrentPage',
                'setCurrentPageName',
                'setPerPage',
                'setTotal',
                'setFrom',
                'setTo',
                'setResultsLoaded',
                'setUrl',
                'setIsLoading',
                'resetSearchVars'
            ]),
            ...mapActions([
                'syncLoadMakesAction',
                'updateUrlAction',
            ]),
            getShortlist(){
                this.url = process.env.VUE_APP_API_URL +'/api/ad-elastic-filters?';
                this.url += 'adIds=' + this.likes.toString();
                this.setUrl(this.url);
                if(this.likes.length > 0) {
                    this.fetchSearchShortlist(this.url);
                }else{
                    this.ready = true;
                    this.setIsLoading(false);
                }
            },
            loadLikes(){
                this.likes = localStorage.getItem('likes') != null ? JSON.parse(localStorage.getItem('likes')).filter(function(value){return value != null && !isNaN(value) }) : [];
            },
            async fetchSearchShortlist (url) {
                await this.$store.dispatch('fetchSearchShortlist', url);
                this.ready = true;
            }
        }
    }
</script>

<style scoped>
</style>
